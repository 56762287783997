/* navbar8.css */

.navbar8-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: sticky;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar8-navbar-interactive {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}

/* Desktop Menu */
.navbar8-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.navbar8-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}

.thq-link {
  cursor: pointer;
}

.navbar8-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navbar8-action2 {
  display: flex;
  flex-direction: row;
}

/* Mobile Menu */
.navbar8-burger-menu {
  display: none;
}

.navbar8-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar8-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar8-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar8-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.navbar8-logo {
  height: 3rem;
}

.navbar8-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar8-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar8-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}

.navbar8-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content label {
  margin-bottom: 1rem;
  font-weight: bold;
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content textarea {
  resize: vertical;
  min-height: 100px;
}

.modal-content button {
  padding: 0.75rem;
  background-color: var(--dl-color-primary-500);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem;
}

.modal-content button:hover {
  background-color: var(--dl-color-primary-700);
}

/* Responsive Styles */
@media (max-width: 767px) {
  .navbar8-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .navbar8-desktop-menu {
    display: none;
  }

  .navbar8-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar8-mobile-menu {
    display: flex;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
}

@media (max-width: 479px) {
  .navbar8-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }

  .navbar8-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
